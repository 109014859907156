import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import CopyPaste from "../../../../src/components/copyPaste/CopyPaste.js";
import * as React from 'react';
export default {
  Img,
  Link,
  graphql,
  CopyPaste,
  React
};