// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-checklist-confirmation-js": () => import("./../../../src/pages/checklist-confirmation.js" /* webpackChunkName: "component---src-pages-checklist-confirmation-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-form-fb-talk-js": () => import("./../../../src/pages/lp/form/fb-talk.js" /* webpackChunkName: "component---src-pages-lp-form-fb-talk-js" */),
  "component---src-pages-lp-lm-growth-checklist-js": () => import("./../../../src/pages/lp/lm/growth-checklist.js" /* webpackChunkName: "component---src-pages-lp-lm-growth-checklist-js" */),
  "component---src-pages-lp-merchants-1-js": () => import("./../../../src/pages/lp/merchants-1.js" /* webpackChunkName: "component---src-pages-lp-merchants-1-js" */),
  "component---src-pages-lp-merchants-2-js": () => import("./../../../src/pages/lp/merchants-2.js" /* webpackChunkName: "component---src-pages-lp-merchants-2-js" */),
  "component---src-pages-lp-rt-merchants-1-js": () => import("./../../../src/pages/lp/rt/merchants-1.js" /* webpackChunkName: "component---src-pages-lp-rt-merchants-1-js" */),
  "component---src-pages-lp-thank-you-page-growth-checklist-js": () => import("./../../../src/pages/lp/thank-you-page/growth-checklist.js" /* webpackChunkName: "component---src-pages-lp-thank-you-page-growth-checklist-js" */),
  "component---src-pages-merchant-hc-advanced-manually-add-widget-to-my-website-mdx": () => import("./../../../src/pages/merchant-hc/advanced/manually-add-widget-to-my-website.mdx" /* webpackChunkName: "component---src-pages-merchant-hc-advanced-manually-add-widget-to-my-website-mdx" */),
  "component---src-pages-merchant-hc-sizify-basics-getting-started-mdx": () => import("./../../../src/pages/merchant-hc/sizify-basics/getting-started.mdx" /* webpackChunkName: "component---src-pages-merchant-hc-sizify-basics-getting-started-mdx" */),
  "component---src-pages-merchant-hc-troubleshooting-widget-not-appearing-on-website-mdx": () => import("./../../../src/pages/merchant-hc/troubleshooting/widget-not-appearing-on-website.mdx" /* webpackChunkName: "component---src-pages-merchant-hc-troubleshooting-widget-not-appearing-on-website-mdx" */),
  "component---src-pages-merchant-help-center-js": () => import("./../../../src/pages/merchant-help-center.js" /* webpackChunkName: "component---src-pages-merchant-help-center-js" */),
  "component---src-pages-merchants-js": () => import("./../../../src/pages/merchants.js" /* webpackChunkName: "component---src-pages-merchants-js" */),
  "component---src-pages-merchants-lp-1-js": () => import("./../../../src/pages/merchants-lp1.js" /* webpackChunkName: "component---src-pages-merchants-lp-1-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-shopper-hc-faq-how-to-start-using-sizify-mdx": () => import("./../../../src/pages/shopper-hc/faq/how-to-start-using-sizify.mdx" /* webpackChunkName: "component---src-pages-shopper-hc-faq-how-to-start-using-sizify-mdx" */),
  "component---src-pages-shopper-hc-faq-update-clothing-size-information-mdx": () => import("./../../../src/pages/shopper-hc/faq/update-clothing-size-information.mdx" /* webpackChunkName: "component---src-pages-shopper-hc-faq-update-clothing-size-information-mdx" */),
  "component---src-pages-shopper-help-center-js": () => import("./../../../src/pages/shopper-help-center.js" /* webpackChunkName: "component---src-pages-shopper-help-center-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-images-page-images-page-js": () => import("./../../../src/templates/images-page/images-page.js" /* webpackChunkName: "component---src-templates-images-page-images-page-js" */)
}

